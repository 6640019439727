

.drag-drop {
    background: #fff;
    border: 1px solid var(--border-color);
    border-radius: 8px;
  }
  .document-uploader {
    border: 2px dashed #4282fe;
    background-color: #f4fbff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
  }
  .document-uploader.active {
    border-color: #6dc24b;
  }
  .document-uploader .upload-info {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .document-uploader .upload-info svg {
    font-size: 36px;
    margin-right: 1rem;
  }
  .document-uploader .upload-info div p {
    margin: 0;
    font-size: 16px;
  }
  .document-uploader .upload-info div p:first-child {
    font-weight: bold;
  }
  .document-uploader .file-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    /* height: 30vh; */
  }
  .document-uploader .file-list__container {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .document-uploader .file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
  }
  .document-uploader .file-item .file-info {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    flex: 1;
  }
  .document-uploader .file-item .file-info p {
    margin: 0;
    font-size: 14px;
    color: #333;
  }
  .document-uploader .file-item .file-actions {
    cursor: pointer;
  }
  .document-uploader .file-item .file-actions svg {
    font-size: 18px;
    color: #888;
  }
  .document-uploader .file-item .file-actions:hover svg {
    color: #d44;
  }
  .document-uploader .browse-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--primary-color);
  }
  .document-uploader .browse-btn:hover {
    background-color: transparent;
  }
  .document-uploader .success-file {
    display: flex;
    align-items: center;
    color: #6dc24b;
  }
  .document-uploader .success-file p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
  }
  .document-uploader input[type="file"] {
    display: none;
  }
  