.about-header{
    padding-block: 3rem;
    background-color: rgb(255, 226, 198);
    color: rgb(62, 29, 2);
}
.clients-wrapper{
    padding-block: 3rem;
    background-color: #ff8800;
}
.aa-counter {
    font-size: 65px;
    display: block;
    color: #fff;
    font-family: Nunito, Arial, sans-serif;
    width: 100%;
    font-weight: 300;
    margin-bottom: 0;
}
.aa-counter-label {
    color: rgba(255, 255, 255, .8);
    font-size: 20px;
    display: block;
    font-weight: 500;
}