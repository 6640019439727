:root {
  --bs-primary-rgb: 255, 111, 0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  background-color: #fff0e6 !important;
  /* padding-bottom: 10rem; */
}
.header{
  color: #fff;
  background-color: #fb7117;
}
.header button{
  border: none;
  background-color: white;
  color: #fb7117;
}
.header button:hover{
  border: 1px solid #fff;
  background-color: #fb7117;
}
.header .dropdown-item{
  font-weight: 700;
}
.header .nav-link{
  font-size: 1.25rem;
}
.header .dropdown-menu{
    border-radius: 16px;
    width: 100%;
    border: none;
    box-shadow: 3px 4px 1pc -8px #00000078;
}
.row{
  justify-content: center;
}
.main-container{
  margin-left: auto;
  margin-right: 2em;
  width: calc(100% - 500px) !important;
  display: flex;
  padding: 4em 4em 8em 4em !important;
}

.form1{
  height: fit-content;
  width: 55vw;
  max-width: 660px;
    padding: 1rem;
    background: #f3e3d6;
    border-radius: 12px;
}
a{
  text-decoration: none !important;
  color: inherit !important;
}
.formoption{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin: 0 10px;
  cursor: pointer;
}
.delay{
  width: 150px !important;
  height: 180px !important;
}
.form2{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
  /* gap: 10px; */
}
.airport-list{
  max-width: 50%;
}
.connectingform{
  padding: 1rem;
  background: #ffffff54;
  border-radius: 12px;
  box-shadow: 2px 2px 8px 1px #0000003b;
}
.flight{
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: #f3e3d6;;
  padding: 5px;
}
.flight-img{
  width: 100%;
}
.form-control, .form-select{
  padding: .75rem .75rem !important;
  font-size: 1.1rem !important;
}
.form-label {
  font-size: 1.1rem;
}
.compensation__confirmed{
  padding: 2rem;
  border-radius: 10px;
  border: 1px dashed grey;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.bg-media{
  left: -10px;
  top: -20px;
  height: fit-content;
}

.caret{
  transform: rotate(180deg);
}
.myApp{
  display: flex;
  align-items: center;
}
.sidebar{
  min-width: 392px;
  max-width: 392px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ff6f00;
  color: white;

}
.sidebar-header{
  border-bottom: 1px solid white;
}
.siderbar-title{
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 28px;
  font-weight: 700;
}
.steps_review {
  display: flex;
  margin-bottom: 19px;
}
.leftPart{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 8px;
}
.leftPart span{
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  border-radius: 50%;
  border: 1px solid #ffffff77;
}
.leftPart span svg{
  opacity: 0;
}
.steps_review.current .leftPart span svg{
  opacity: 1;
}
.steps_review.filled .leftPart span svg{
  opacity: 1;
  stroke: #8cbcff;
}
.steps_review.filled .leftPart span svg path{
  stroke: #ff5500;
}
.steps_review.current .leftPart span{
  border-color: #fff;
}
.steps_review.filled .leftPart span{
  background-color: #fff;
}
.dotted-line{
  position: absolute;
  top: 55px;
}
.leftPart>svg{
  position: absolute;
  top: 41px;
}
.steps_review.current .leftPart>svg path{
  stroke: white;
}
.rightPart p{
  font-size: 0.9rem;
  margin-bottom: 0;
}
.rightPart{
  color: #ffffff77;
}
.steps_review.current .rightPart{
  color: white;
}
.topbar{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* border: 1px solid; */
  
}
.top-step-elem{
  flex: 1;
}
.top-step-elem p{
  color: grey;
  border-bottom: 2px solid grey;
  display: flex;
  font-weight: 700;
}
.top-step-elem.current p{
  color: rgb(23, 118, 200);
  border-bottom: 2px solid rgb(23, 118, 200);
  /* flex: 1; */
}
.top-step-elem.filled p{
  color: black;
  border-bottom: 2px solid black;
  /* flex: 1; */
  display: flex;
  gap: 5px;
}

.bottom-btn{
  left: 392px;
}
.wrapper{
  width: 100%;
}
.faq-accordian{
  border-radius: 10px !important;
}
.faq-accordian::before{
  content: none !important;
}
.faq-top-section{
  color: white;
  background: no-repeat top;
  background-size: cover;
  height: 300px;
  text-align: center;
}
.faq-top-section h1{
  font-size: 30px;
    /* font-weight: 300; */
    margin: 20px 0 40px;
}

.compensation{
  background: linear-gradient(45deg, #252421 0, #294165 100%);
}
.compensation-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: white;
  max-width: 820px;
}
.hero__section{
  padding-top: 209px;
    padding-bottom: 209px;
    background: url('../src/components/assets/heroclouds.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
    background-color: white;
}
.hero__section svg{
  color: orange;
}

/* .hero-left p{
  font-size: 1.5rem;
} */

.based{
  padding: 10px 16px;
    background: #F3F6F9;
    border-radius: 8px;
    margin-bottom: 20px;
    color: inherit;
}
.hero-right{
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
}
.hero-right img{
  max-width: 427px;
    height: auto;
    position: absolute;
    top: -50%;
}
.how-it-works{
  width: 100%;
  padding-top: 128px;
    background: url('../src/components/assets/cloudshow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 0 100%;
    background-color: #f3f6f9;
}
.how-it-works-left{
  justify-content: center;
  align-items: center;

}
.how-it-works-left img{
  max-width: 600px;
  /* width: 280px; */
  height: auto;
}
.howit-elem{
  padding: 1.5rem;
  border: none;
  border-radius: 20px;
  background-color: white;
  color: #294165;
}
.info-part{
  background-color: #fb7117;
}

.problems__wrapper{
  padding-top: 140px;
  padding-bottom: 100px;
  background-color: white;
}
.problem-header{
  max-width: 800px;
}
.problem-header p{
  margin: 0;
    font-size: 18px;
    line-height: 27px;
    color: #91a0b2;
    font-weight: 400;
}
.compensation-types{
  /* display: flex;
  justify-content: center; */
  margin-block: 70px;
  /* gap: 20px; */
  /* grid-column-gap: 20px; */
}
.compensation-type{
  border-radius: 20px;
  padding: 1.5rem;
  border: 1px solid #f15f00;
  min-height: 320px;
  color: #f15f00;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.6s;
}
.compensation-type:hover{
  background-color: #fb7117;
  color: white;
  border: none;
}
.planelight , .compensation-type p{
  display: none;
}
.compensation-type:hover .planedark{
  display: none;
}
.compensation-type:hover p,.compensation-type:hover .planelight{
  display: block;
}
.compensation-type img{
  width: 2.5rem;
}
.protect-passenger{
  background: url('../src/components/assets/protectioncloud.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom right;
    background-color: #fb7117;
    padding: 70px 0px;
}
.outer__protect{
  padding: 30px 40px;
  background: #fff;
  border-radius: 10px;
}
.outer__protect p{
  font-size: 16px;
  line-height: 20px;
  color: #172f6f;
  margin-right: 50px;
}
.outer__protect ul{
  padding: 0;
    list-style-type: none;
    margin: 0;
    color: #fb7117;
}
.about__wrapper{
  padding: 140px 0;
  background: #f3f6f9;
  color: #172f6f;
  /* line-height: 51px; */
}
.about__wrapper .title{
  margin-bottom: 40px;
  color: #172f6f;
}
.about__wrapper .about__desc{
  max-width: 740px;
}
.about__desc p{
  margin-top: 0;
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 30px;
}
.about__desc .read-more {
  font-size: 22px;
  line-height: 33px;
  color: #fb7117 !important;
  text-decoration: none !important;
  font-weight: 700;
}
.about__video{
  margin-left: 70px;
}
.video_play_block--show:before {
  content: url('data:image/svg+xml;utf8,<svg width="128" height="128" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9z" fill="white"/></svg>');
  position: absolute;
  color: #fff;
  top: 50%;
  margin-top: -75px;
  left: 50%;
  margin-left: -64px;
  pointer-events: none;
  z-index: 1;
  font-size: 130px;
}
.about__video img{
  max-width: 415px;
  height: auto;
}
.claim_wrapper{
  background: url('../src/components/assets/clouds.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  overflow: hidden;
  background-color: #fb7117;
  padding: 100px 0;
}
.claim__desc .claim_desc_h4{
  margin-top: 0;
  margin-bottom: 40px;
  font-size: 36px;
  line-height: 43px;
  color: #fff;
  font-weight: 700;
}
.claim_desc_button_w {
  margin-bottom: 60px;
}
.regular-btn{
  background-color: #fff;
  border: 1px solid #fff;
  color: #fb7117 !important;
  padding-left: 32px;
    padding-right: 32px;
    min-height: 56px;
    white-space: normal;
    text-align: center;
    min-width: 305px;
    border-radius: 10px;
    outline: 0 !important;
    cursor: pointer;
    transition: all 0.2s;
}
.regular-btn:hover{
  background-color: #fb7117;
  color: white !important;
}

.claim_desc_p {
  margin: 0;
  font-size: 24px;
  line-height: 26px;
  color: #fff;
  font-weight: 400;
}
.claim_w_media {
  position: absolute;
  right: -120px;
  bottom: -100px;
}

.reviews__wrapper {
  padding: 70px 0;
  background-color: #f3f6f9;
}
.outer__reviews {
  padding: 30px 40px;
  background: #fff;
  border-radius: 10px;
}
.button-wrapper>span {
  font-size: 24px;
  line-height: 33px;
  background: #fb7117;
  border: 1px solid #fb7117;
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  color: #fff;
  outline: 0 !important;
}
.footer{
  border-top: 2px solid #8080805c;
  padding-block: 20px;
  background-color: #f3f6f9;
}
.footer p{
  color: #91a0b2;
}
.footer a{
  color: #fb7117 !important;
}
.footer .social_links{
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fb7117;
}
.footer .social_links svg{
  width: 1.5rem;
  height: 1.5rem;
}

@media screen and (max-width : 992px){
  
  .main-container{
    width: auto !important;
    margin-right: auto ;
    padding-top: 3rem;
  }
  .bottom-btn{
    left: 0;
  }
  .form1{
    width: 70vw;
  }
  .about__video img{
    width: 100% !important;
  }
  .about__desc{
    margin-top: 1rem;
  }
  .compensation-type p{
    display: block;
  }
}

.icon-input {
  padding-right: 45px !important;
}

.PhoneInputInput {
  border: none;
}

.PhoneInputInput:focus {
  outline: none;
}


.text-primary {
  --bs-primary: "#ff6f00";
  color: var(--bs-primary) !important;
}